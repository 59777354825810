import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import Loading from 'core-system/Loading'
import styled from 'styled-components'
import OnboardingInvite from './OnboardingInvite'
import OnboardingUpload from './OnboardingUpload'
import OnboardingAddPayment from './OnboardingAddPayment'
import { StaticAssetsUrl } from 'redux/config/services/Constants'
import Text from 'core-system/Text'
import pxToRem from 'core-system/utils/pxToRem'
import { employerOnboardingStepData } from './OnboardingUtils'
import OnboardingWorkforceSetup from './OnboardingWorkforceSetup'
import { useLocation, useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'
import OnboardingUploadCsv from './OnboardingUploadCsv'
import OnboardingUploadCsvSteps from './OnboardingUploadCsvSteps'
import OnboardingFinchSync from './OnboardingFinchSync'
import UrlParser from 'shared/UrlParser'
import OnboardingSuccess from './OnboardingSuccess'
import SegmentService from 'redux/config/services/SegmentService'
import { employerActions } from 'redux/employer/employerSlice'
import { useDispatch } from 'react-redux'
import FleetLogoNew from 'core-system/Icons/Misc/FleetLogoNew'
import palette from 'core-system/Themes/palette'
import { userActions } from 'redux/user/userSlice'
import OnboardingEmployerDetails from './OnboardingEmployerDetails'

const Container = styled.div`
  display: flex;
  height: 100%;
`

const LeftContainer = styled.div`
  width: 48%;
  padding: 2.5rem 4rem;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: ${(props) => props.theme.palette.white};
  &::-webkit-scrollbar {
    display: none;
  }
`

const LogoContainer = styled.div`
  top: 3rem;
  padding-bottom: 3rem;
  left: 3rem;
`

const RightContainer = styled.div<{ backgroundColor: string }>`
  width: 52%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`

const Img = styled.img`
  align-self: center;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const NavFooter = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  justify-content: right;
`

const PageIndicators = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
  justify-content: center;
  align-items: center;
  align-self: center;
`

const Page = styled.div<{ isActive: boolean }>`
  border-radius: 50%;
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.secondary.maroon1
      : props.theme.palette.grey.grey3};
`

const Uploading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UploadImg = styled.img`
  width: ${(props) => props.theme.pxToRem(460)};
  padding-top: 1.25rem;
`

const ProgressBar = styled.div`
  height: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 5rem;
  background-color: ${(props) => props.theme.palette.secondary.purple5};
`

const Progress = styled.div<{ percentage: number }>`
  width: ${(props) => `${props.percentage}%`};
  height: 100%;
  border-radius: 0.5rem;

  background-color: ${(props) => props.theme.palette.primary.primaryPurple};
  transition: width 0.3s;
`
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  align-content: center;
  margin-top: auto;
  width: 100%;
  justify-content: right;
  padding-block: 1.5rem;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const StyledLink = styled(Text)`
  color: ${(props) => props.theme.palette.secondary.maroon1};
  text-decoration: underline;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`

const getImage = (currentStep: OnboardingStep) => {
  if (currentStep === 'setupWorkforce')
    return `${StaticAssetsUrl}/onboarding/onboarding_add_workers.png`
  else if (currentStep === 'addPayment')
    return `${StaticAssetsUrl}/onboarding/onboarding_add_payment.png`
  else if (currentStep === 'success')
    return `${StaticAssetsUrl}/onboarding/onboarding_success.png`
  else return `${StaticAssetsUrl}/onboarding/onboarding_add_workers.png`
}

const getNextStep = (currentStep: OnboardingStep, isBack: boolean) => {
  if (currentStep === 'setupWorkforce') {
    return isBack ? 'setupWorkforce' : 'csvUpload'
  } else if (currentStep === 'csvUpload') {
    return isBack ? 'setupWorkforce' : 'addPayment'
  } else if (currentStep === 'addPayment') {
    return isBack ? 'csvUpload' : 'success'
  } else if (currentStep === 'inviteAdmins') {
    return isBack ? 'addPayment' : 'setupWorkforce'
  } else {
    return 'uploading'
  }
}

export type OnboardingStep =
  | 'setupWorkforce'
  | 'finchSync'
  | 'employerDetails'
  | 'addPayment'
  | 'inviteAdmins'
  | 'automatedSetup'
  | 'addWorksites'
  | 'addEmployees'
  | 'uploading'
  | 'csvUpload'
  | 'success'

const GettingStartedView = React.memo(() => {
  const location = useLocation()
  const qp = UrlParser.getQueryParams(location.search)
  const dispatch = useDispatch()

  const { profile, worksites } = useSelector(
    (state: AppState) => state.employer
  )
  const fileUploadHistory = useSelector(
    (state: AppState) => state.employer.fileUploadHistory
  )

  const navigate = useNavigate()

  const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(
    qp.step ? qp.step : profile?.lastOnboardingStep || 'setupWorkforce'
  )

  const handleNextStep = (isBack?: boolean) => {
    if (onboardingStep === 'addPayment' && !isBack) {
      navigate(Locations.GettingStarted + '?step=success')
    }
    setOnboardingStep(getNextStep(onboardingStep, isBack))
  }
  const [fakeProcessing, setFakeProcessing] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (onboardingStep === 'uploading') {
      const interval = setInterval(() => {
        setFakeProcessing((fakeProcessing) => fakeProcessing + 5)
      }, 2000)
      return () => clearInterval(interval)
    }

    SegmentService.track('employer-onboarding-step', {
      step: onboardingStep,
      employerId: profile?.id,
    })

    dispatch(
      employerActions.updateOnboardingStep(
        onboardingStep === 'success' ? 'finchSync' : onboardingStep
      )
    )

    return undefined
    // eslint-disable-next-line
  }, [onboardingStep])

  if (
    !worksites ||
    !profile ||
    !fileUploadHistory ||
    (!onboardingStep && onboardingStep !== 'addPayment')
  ) {
    return <Loading fullPage withoutNav />
  }

  const getContent = (onboardingStep: OnboardingStep, timer: number) => {
    switch (onboardingStep) {
      case 'setupWorkforce':
        return (
          <OnboardingWorkforceSetup
            setLoading={setLoading}
            setOnboardingStep={setOnboardingStep}
          />
        )
      case 'finchSync':
        return (
          <OnboardingFinchSync
            setLoading={setLoading}
            setOnboardingStep={setOnboardingStep}
          />
        )
      case 'employerDetails':
        return <OnboardingEmployerDetails setLoading={setLoading} />
      case 'csvUpload':
        return (
          <OnboardingUploadCsvSteps
            setLoading={setLoading}
            setOnboardingStep={setOnboardingStep}
          />
        )
      case 'addPayment':
        return (
          <OnboardingAddPayment handleNextStep={() => handleNextStep(false)} />
        )
      case 'success':
        return <OnboardingSuccess />
      case 'inviteAdmins':
        return <OnboardingInvite handleNextStep={handleNextStep} />
      case 'addWorksites':
        return <OnboardingUpload />
      case 'uploading':
        return (
          <Uploading>
            <UploadImg
              alt='upload'
              src={`${StaticAssetsUrl}/onboarding/onboarding-processing.png`}
            />
            <ProgressBar>
              <Progress percentage={timer > 65 ? 65 : timer} />
            </ProgressBar>
          </Uploading>
        )
      default:
        return (
          <Text variant='body1'>
            Oops, something went wrong, try again or contact support
          </Text>
        )
    }
  }

  return (
    <Container>
      <LeftContainer>
        <LogoContainer>
          <FleetLogoNew height='40' width='190' />
        </LogoContainer>
        <Text variant='h1' color='black' marginBottom='1.5rem'>
          {employerOnboardingStepData[onboardingStep].title}
        </Text>
        {employerOnboardingStepData[onboardingStep].subtitle && !loading ? (
          <Text
            variant='h3'
            color='black'
            marginBottom='2rem'
            fontFamily={'PolySans'}
            fontWeight={500}
          >
            {employerOnboardingStepData[onboardingStep].subtitle}
          </Text>
        ) : null}
        {loading ? (
          <LoadingContainer>
            <Loading isCard={false} />
            <Text variant='h3' textAlign='center'>
              {employerOnboardingStepData[onboardingStep].loading}
            </Text>
          </LoadingContainer>
        ) : (
          getContent(onboardingStep, fakeProcessing)
        )}
        {onboardingStep !== 'uploading' && (
          <BottomContainer>
            <Text
              variant='action2'
              style={{
                display: 'flex',
                whiteSpace: 'nowrap',
              }}
              textColor={palette.black}
            >
              <StyledLink
                variant='action2'
                onClick={() => dispatch(userActions.logout())}
              >
                Log out
              </StyledLink>
              &nbsp;
              {onboardingStep !== 'success' && 'and complete later'}
            </Text>
            {/* Commented out for now till we migrate to the new onboarding flow */}
            {/* {!loading && onboardingStep === 'success' ? (
              <Button
                onClick={() =>
                  navigate(Locations.Dashboard + '?product_tour_id=544582')
                }
                style={{
                  alignSelf: 'center',
                  width: '100%',
                  marginBlock: pxToRem(40),
                  paddingBlock: pxToRem(20),
                }}
              >
                View Tour
              </Button>
            ) : null} */}
            <NavFooter>
              {onboardingStep !== 'success' && (
                <PageIndicators>
                  <Page
                    isActive={
                      onboardingStep === 'setupWorkforce' ||
                      onboardingStep === 'csvUpload'
                    }
                  />
                  <Page isActive={onboardingStep === 'addPayment'} />
                  <Page isActive={onboardingStep === 'finchSync'} />
                  <Page isActive={onboardingStep === 'employerDetails'} />
                </PageIndicators>
              )}
            </NavFooter>
            {/* {!loading && onboardingStep === 'addPayment' ? (
              <Button
                onClick={() => handleNextStep(false)}
                disabled={
                  onboardingStep === 'addPayment' &&
                  profile?.platformFeeDetails?.length === 0
                }
                style={{
                  position: 'absolute',
                  bottom: '1.75rem',
                  right: '2rem',
                }}
              >
                Next
              </Button>
            ) : null} */}
          </BottomContainer>
        )}
      </LeftContainer>
      <RightContainer
        backgroundColor={
          employerOnboardingStepData[onboardingStep].backgroundColor
        }
      >
        {onboardingStep !== 'csvUpload' ? (
          <>
            <Img alt='onboarding' src={getImage(onboardingStep)} />
          </>
        ) : (
          <OnboardingUploadCsv setOnboardingStep={setOnboardingStep} />
        )}
      </RightContainer>
    </Container>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  GettingStartedView.displayName = 'GettingStartedView'
}

export default GettingStartedView
