import Text from 'core-system/Text'
import TextField from 'core-system/TextFieldV2'
import palette from 'core-system/Themes/palette'
import pxToRem from 'core-system/utils/pxToRem'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import UserService from 'redux/user/userService'
import { userActions } from 'redux/user/userSlice'
import { Locations } from 'shared/Router/Locations'
import UrlParser from 'shared/UrlParser'
import styled from 'styled-components'
import {
  invalidEmailProviders,
  passwordNoUnderscoreCheck,
  passwordNumberCheck,
  passwordSpecialCharCheck,
  signUpCopy,
} from './shared/LoginSignUpUtils'
import FleetLogoNew from 'core-system/Icons/Misc/FleetLogoNew'
import LoginSignUpGraphicV2, {
  ImageLabel,
} from './components/LoginSignUpGraphicV2'
import ButtonV2 from 'core-system/ButtonV2'

const Container = styled.div`
  display: flex;
  height: 100%;
`

export const StyledLink = styled(Link)`
  ${(props) => props.theme.typography.action2};
  color: ${(props) => props.theme.palette.secondary.maroon1};
  text-decoration: underline;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
`

const FormContainer = styled.div`
  display: flex;
  width: 48%;
  background-color: ${palette.white};
  padding: 7rem 4rem 3rem;
  position: relative;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const LogoContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 3rem;
`

const LoginNavContainer = styled.div`
  display: flex;
  margin-top: ${(props) => props.theme.pxToRem(40)};
  align-items: center;

  flex-direction: row;
`

const TextContainer = styled.div`
  margin: 1rem;
  color: ${(props) => props.theme.palette.grey.grey10};
`

const PolicyLink = styled.a`
  color: ${(props) => props.theme.palette.secondary.maroon1};
`

const InputContainer = styled.div<{ inline?: boolean }>`
    margin-bottom: 1rem;
    max-width: ${pxToRem(425)}
    display: flex;
    flex-direction: ${(props) => (props.inline ? 'row' : 'column')};

    ${(props) =>
      props.inline
        ? {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
            '> div': {
              flex: 1,
            },
          }
        : null}

    @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
        display: block;
        > div {
            max-width: 100%;
            margin-bottom: 1.5rem;
        }
    }
`

const HelpText = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.5s ease-in-out;
  background-color: ${(props) =>
    props.error
      ? props.theme.palette.secondary.red2
      : props.theme.palette.secondary.green3};

  > div {
    color: ${(props) =>
      props.error
        ? props.theme.palette.secondary.red1
        : props.theme.palette.secondary.green1};
  }
`

// const AuthBtnContainer = styled.div`
//   display: grid;
//   column-gap: 1.25rem;
//   row-gap: 1rem;
//   grid-template-columns: repeat(
//     auto-fill,
//     ${(props) => props.theme.pxToRem(254)}
//   );

//   a {
//     text-decoration: none;
//     white-space: nowrap;
//   }
// `

// const ButtonContainer = styled.a`
//   ${(props) => props.theme.typography.action1}
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   svg {
//     margin-right: 0.75rem;
//   }
// `

// const formatWebsiteURL = (url: string) => {
//   return url.startsWith('http') ? url : `https://${url}`
// }

const isValidEmail = (email: string) => {
  // Get the email provider
  const domain = email.split('@')[1]?.split('.')[0]
  return !invalidEmailProviders.has(domain)
}

const getDefaultSignUpDetails = () => {
  return {
    // firstName: '',
    // lastName: '',
    email: '',
    password1: '',
    // name: '',
    // website: '',
    // workforceSize: '',
  }
}

const invalidPassword = (password: string) => {
  return (
    password.length < 10 ||
    !passwordSpecialCharCheck(password) ||
    !passwordNumberCheck(password) ||
    !passwordNoUnderscoreCheck(password)
  )
}

const EmployerSignUpView = React.memo(() => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const qp = UrlParser.getQueryParams(location.search)

  const [signUpDetails, setSignUpDetails] = useState(getDefaultSignUpDetails())

  const [fieldsInvalid, setFieldsInvalid] = useState({
    // firstName: false,
    // lastName: false,
    email: false,
    password1: false,
    // name: false,
    // website: false,
    // workforceSize: false,
  })

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [helpText, setHelpText] = useState({
    error: false,
    msg: null,
  })

  // Social Auth signup success redirect back to app
  useEffect(() => {
    if (qp.code && qp.state) {
      dispatch(
        userActions.socialSignup({
          code: qp.code,
          platformType: 'employer',
        })
      )
    }
  }, [qp, dispatch])

  const checkMissingFields = useCallback(() => {
    setFieldsInvalid({
      email:
        !signUpDetails.email || !isValidEmail(signUpDetails.email)
          ? true
          : false,
      // firstName: !signUpDetails.firstName ? true : false,
      // lastName: !signUpDetails.lastName ? true : false,
      password1:
        !signUpDetails.password1 || invalidPassword(signUpDetails.password1)
          ? true
          : false,
      // name: !signUpDetails.name ? true : false,
      // website: !signUpDetails.website ? true : false,
      // workforceSize:
      //   signUpDetails.workforceSize === '' ||
      //   parseInt(signUpDetails.workforceSize) <= 0 ||
      //   !signUpDetails.workforceSize
      //     ? true
      //     : false,
    })
  }, [signUpDetails])

  const onValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // if (name === 'workforceSize') {
    //   if (value === '') {
    //     setSignUpDetails({ ...signUpDetails, [name]: value })
    //   } else if (!isNaN(parseInt(value))) {
    //     setSignUpDetails({
    //       ...signUpDetails,
    //       [name]: parseInt(value).toString(),
    //     })
    //   }
    // } else {
    setSignUpDetails({ ...signUpDetails, [name]: value })
    // }
  }

  const signup = useCallback(() => {
    UserService.employerSignUp({
      ...signUpDetails,
      // workforceSize: parseInt(signUpDetails.workforceSize),
      // website: formatWebsiteURL(signUpDetails.website),
    })
      .then(() => {
        SegmentService.track('employer-signup-action', {
          email: signUpDetails.email,
          // firstName: signUpDetails.firstName,
          // lastName: signUpDetails.lastName,
          // companyName: signUpDetails.name,
          // website: signUpDetails.website,
          // workforceSize: signUpDetails.workforceSize,
          action: 'success',
        })
        setHelpText({
          error: false,
          msg: signUpCopy.employerSuccess,
        })
        navigate(Locations.EmployerEmailConfirmation)
      })
      .catch((err) => {
        SegmentService.track('employer-signup-action', {
          email: signUpDetails.email,
          // firstName: signUpDetails.firstName,
          // lastName: signUpDetails.lastName,
          // companyName: signUpDetails.name,
          // website: signUpDetails.website,
          // workforceSize: signUpDetails.workforceSize,
          action: 'failed',
          error: err.message,
        })
        setHelpText({
          error: true,
          msg:
            err?.response?.data?.non_field_errors?.length > 0
              ? err.response.data.non_field_errors[0]
              : signUpCopy.employerFail,
        })
      })
      .finally(() => {
        setIsButtonDisabled(false)
      })
  }, [signUpDetails, navigate])

  const handleSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      const validEmployerInfo =
        signUpDetails &&
        Object.keys(signUpDetails).every(
          (key) =>
            // (key === 'workforceSize' && parseInt(signUpDetails[key]) > 0) ||
            (key === 'email' &&
              signUpDetails[key].trim() !== '' &&
              signUpDetails[key] !== null &&
              isValidEmail(signUpDetails[key])) ||
            (signUpDetails[key].trim() !== '' && signUpDetails[key] !== null)
        )
      if (!validEmployerInfo) {
        checkMissingFields()
      } else {
        checkMissingFields()
        setIsButtonDisabled(true)
        setHelpText({ error: false, msg: null })
        signup()
      }
    },
    [signUpDetails, checkMissingFields, signup]
  )

  return (
    <>
      <Helmet>
        <title>Employer Sign Up | Fleet</title>
      </Helmet>
      <Container>
        <FormContainer>
          <LogoContainer>
            <FleetLogoNew height='40' width='190' />
          </LogoContainer>
          <Text variant='h1' marginBottom='1rem' color={palette.black}>
            Join Fleet Today!
          </Text>
          <Text
            variant='action2'
            marginBottom='40px'
            maxWidth='35rem'
            color={palette.black}
          >
            {signUpCopy.employer}
          </Text>
          <form>
            {/* <InputContainer inline>
              <TextField
                label='First Name*'
                placeholder='First Name'
                name='firstName'
                type='text'
                value={signUpDetails.firstName}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.firstName}
              />
              <TextField
                label='Last Name*'
                placeholder='Last Name'
                name='lastName'
                type='text'
                value={signUpDetails.lastName}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.lastName}
              />
            </InputContainer> */}
            {/* 
            <Divider margin='1.5rem 0' /> */}

            {/* <AuthBtnContainer> */}
            {/* <Button variant='secondary' size='large'>
                <ButtonContainer
                  href={GoogleSignupAuthUrl}
                  onClick={() =>
                    SegmentService.track('social-signup-click', {
                      provider: 'google',
                    })
                  }
                >
                  <GoogleIcon />
                  Signup with Google
                </ButtonContainer>
              </Button> */}
            {/* <Button variant='secondary' size='large'>
                <ButtonContainer
                  href={MsftAuthUrl}
                  onClick={() =>
                    SegmentService.track('social-signup-click', {
                      provider: 'microsoft',
                    })
                  }
                >
                  <MicrosoftIcon />
                  Signup with Microsoft
                </ButtonContainer>
              </Button> */}
            {/* </AuthBtnContainer> */}

            <InputContainer>
              <TextField
                label='Work Email'
                placeholder='Email'
                name='email'
                type='email'
                value={signUpDetails.email}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.email}
                helpText='Please enter a valid work email address.'
              />
              <Text
                variant='body2'
                textColor={palette.text.placeholder}
                marginTop='0.5rem'
              >
                {signUpCopy.employerEmailHelpText}
              </Text>
            </InputContainer>
            <InputContainer>
              <TextField
                label='Password'
                placeholder='Password'
                name='password1'
                type='password'
                value={signUpDetails.password1}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.password1}
                helpText={'Ensure password meets requirements'}
              />
              <Text
                variant='body2'
                textColor={palette.text.placeholder}
                marginTop='0.5rem'
              >
                {signUpCopy.employerPasswordHelpText}
              </Text>
            </InputContainer>
            {/* <PasswordRequirementChecker password={signUpDetails.password1} /> */}
            {/* <Divider marginY='1rem' /> */}
            {/* <InputContainer>
              <TextField
                label='Company Name*'
                placeholder='Company Name'
                name='name'
                type='text'
                value={signUpDetails.name}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.name}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Company Website*'
                placeholder='Company Website'
                name='website'
                type='text'
                value={signUpDetails.website}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.website}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                label='Workforce Size*'
                placeholder='Workforce Size'
                name='workforceSize'
                value={signUpDetails.workforceSize}
                onChange={onValChange}
                required
                invalid={fieldsInvalid.workforceSize}
                helpText={'Your workforce size must be greater than 0'}
              />
            </InputContainer> */}
          </form>
          {helpText.msg ? (
            <HelpText error={helpText.error}>
              <Text variant='action2' textAlign='center'>
                {helpText.msg}
              </Text>
            </HelpText>
          ) : null}
          <ButtonContainer>
            <ButtonV2 onClick={handleSubmit} disabled={isButtonDisabled}>
              Submit
            </ButtonV2>
          </ButtonContainer>
          <TextContainer>
            By clicking &lsquo;Submit&rsquo;, you agree to Fleet&apos;s Terms of
            Service
            <sup>
              <PolicyLink
                href='https://www.movewithfleet.com/terms-of-use'
                target='_blank'
                rel='noopener noreferrer'
              >
                1
              </PolicyLink>
            </sup>
            {'  '}
            and Privacy Policy
            <sup>
              <PolicyLink
                href='https://www.movewithfleet.com/privacy-policy'
                target='_blank'
                rel='noopener noreferrer'
              >
                2{' '}
              </PolicyLink>
            </sup>
            , as well as those of our integrated partners.
            <sup>
              <PolicyLink
                href='https://stripe.com/connect-account/legal'
                target='_blank'
                rel='noopener noreferrer'
              >
                3
              </PolicyLink>
            </sup>
          </TextContainer>{' '}
          <LoginNavContainer>
            <Text variant='action2' color={palette.black}>
              Already have an account?{' '}
              <StyledLink to={Locations.Login}>Login</StyledLink>
            </Text>
          </LoginNavContainer>
        </FormContainer>
        <LoginSignUpGraphicV2 imageLabel={ImageLabel.SIGNUP} />
      </Container>
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  EmployerSignUpView.displayName = 'EmployerSignUpView'
}

export default EmployerSignUpView
