import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from 'core-system/Text'
import { useDispatch, useSelector } from 'react-redux'
import TextFieldV2 from 'core-system/TextFieldV2'
import FlexContainer from 'core-system/FlexContainer'
import pxToRem from 'core-system/utils/pxToRem'

import palette from 'core-system/Themes/palette'
import { ConnectedPerson } from 'redux/employer/employerTypes'
import ButtonV2 from 'core-system/ButtonV2'
import { employerActions } from 'redux/employer/employerSlice'
import { AppState } from 'redux/config/store'
import { useNavigate } from 'react-router-dom'
import { Locations } from 'shared/Router/Locations'

const InputContainer = styled.div<{ inline?: boolean }>`
  margin-bottom: 1rem;
  border-radius: ${pxToRem('4')};
  ${(props) =>
    props.inline
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
          '> div': {
            flex: 1,
          },
        }
      : null}
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: block;
    > div {
      max-width: 100%;
      margin-bottom: 1.5rem;
    }
  }
`

const FlexContainerWithBackground = styled(FlexContainer)`
  background-color: #fff8f8;
  padding: 1rem;
  border-color: #837377;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.4375rem;
  flex-direction: column;
  margin-bottom: 1rem;
`

interface OnboardingFinchSyncProps {
  setLoading: (loading: boolean) => void
}
const getRepresentativeDefaultState = (): ConnectedPerson => {
  return {
    firstName: '',
    lastName: '',
    relationship: {
      title: '',
      owner: false,
      representative: true,
      executive: false,
      director: false,
      authorizer: false,
      legal_guardian: false,
      percent_ownership: null,
    },
    dob: { day: '', month: '', year: '' },
    ssnLast4: '',
    internalId: '',
  }
}
const getOwnerDefaultState = (): ConnectedPerson => {
  return {
    firstName: '',
    lastName: '',
    ssnLast4: '',
    internalId: '',
  }
}

const OnboardingEmployerDetails = React.memo(
  (props: OnboardingFinchSyncProps) => {
    const { setLoading } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { connectedPersons } = useSelector(
      (state: AppState) => state.employer
    )
    const { email } = useSelector((state: AppState) => state.user.profile)

    const [representative, setRepresentative] = useState(
      getRepresentativeDefaultState()
    )

    const [owner, setOwner] = useState<ConnectedPerson>(getOwnerDefaultState())
    useEffect(() => {
      if (!connectedPersons) {
        dispatch(employerActions.getConnectedPersons())
      }
    }, [connectedPersons, dispatch])

    useEffect(() => {
      if (connectedPersons) {
        setLoading(true)
        const matchingPerson = connectedPersons.find(
          (person) => person.email === email
        )
        if (matchingPerson) {
          setRepresentative(matchingPerson)
        }
        setLoading(false)
      }
    }, [connectedPersons, email, setLoading])

    const saveRepresentatives = () => {
      setLoading(true)
      dispatch(
        employerActions.updateConnectedPerson(representative as ConnectedPerson)
      )
      dispatch(employerActions.createConnectedPerson(owner as ConnectedPerson))
      setLoading(false)
      dispatch(employerActions.updateOnboardingStep('success'))
      navigate(Locations.Dashboard)
    }

    const handleRepresentativeChange = (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { name, value } = e.target

      setRepresentative((prev) => ({
        ...prev,
        [name]: value,
      }))
    }

    const handleOwnerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setOwner((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
    return (
      <FlexContainer flexDirection='column'>
        {/* Success message for finch connect */}
        <Text variant='h3' marginBottom='1rem'>
          We just need a little more information.
        </Text>
        {/* {isLoadingEmployers ? (
          <Text variant='h3' marginBottom='1rem'>
            Loading...
          </Text>
        ) : ( */}
        <>
          <FlexContainerWithBackground>
            <Text variant='h4' marginBottom='1rem' color={palette.text.primary}>
              About you
            </Text>
            <InputContainer inline>
              <TextFieldV2
                label='First Name'
                placeholder={representative?.firstName}
                name='firstName'
                type='text'
                onChange={handleRepresentativeChange}
                value={representative?.firstName}
                required
              />
              <TextFieldV2
                label='Last Name'
                placeholder={representative?.lastName}
                name='lastName'
                type='text'
                onChange={handleRepresentativeChange}
                value={representative?.lastName}
                required
              />
            </InputContainer>
            <InputContainer inline>
              <TextFieldV2
                label='Title'
                placeholder={representative?.relationship?.title}
                name='title'
                type='text'
                onChange={(e) =>
                  setRepresentative((prev) => ({
                    ...prev,
                    relationship: {
                      ...prev.relationship,
                      title: e.target.value,
                    },
                  }))
                }
                value={representative?.relationship?.title}
                required
              />
              <TextFieldV2
                label='Last 4 of SSN'
                placeholder='Last 4 of SSN'
                name='ssnLast4'
                type='number'
                onChange={handleRepresentativeChange}
                value={representative?.ssnLast4}
                required
              />
            </InputContainer>
            <InputContainer inline>
              <TextFieldV2
                label='Birth Month'
                placeholder={representative?.dob?.month}
                name='month'
                type='number'
                onChange={(e) =>
                  setRepresentative((prev) => ({
                    ...prev,
                    dob: { ...prev.dob, month: e.target.value },
                  }))
                }
                value={representative?.dob?.month}
                required
              />
              <TextFieldV2
                label='Birth Date'
                placeholder={representative?.dob?.day}
                name='day'
                type='number'
                onChange={(e) =>
                  setRepresentative((prev) => ({
                    ...prev,
                    dob: { ...prev.dob, day: e.target.value },
                  }))
                }
                value={representative?.dob?.day}
                required
              />
              <TextFieldV2
                label='Birth Year'
                placeholder={representative?.dob?.year}
                name='year'
                type='number'
                onChange={(e) =>
                  setRepresentative((prev) => ({
                    ...prev,
                    dob: { ...prev.dob, year: e.target.value },
                  }))
                }
                value={representative?.dob?.year}
                required
              />
            </InputContainer>
          </FlexContainerWithBackground>
          <FlexContainerWithBackground>
            <Text variant='h4' marginBottom='1rem' color={palette.text.primary}>
              About the owners
            </Text>
            <InputContainer inline>
              <TextFieldV2
                label='First Name'
                placeholder={owner?.firstName}
                name='firstName'
                type='text'
                onChange={handleOwnerChange}
                value={owner?.firstName}
                required
              />
              <TextFieldV2
                label='Last Name'
                placeholder={owner?.lastName}
                name='lastName'
                type='text'
                onChange={handleOwnerChange}
                value={owner?.lastName}
                required
              />
            </InputContainer>
            <InputContainer inline>
              <TextFieldV2
                label='Email'
                placeholder={owner?.email}
                name='email'
                type='email'
                onChange={handleOwnerChange}
                value={owner?.email}
                required
              />
            </InputContainer>
            <InputContainer inline></InputContainer>
          </FlexContainerWithBackground>
        </>
        {/* )} */}
        <ButtonV2 onClick={() => saveRepresentatives()} marginTop='1rem'>
          Save and Continue
        </ButtonV2>
      </FlexContainer>
    )
  }
)

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  OnboardingEmployerDetails.displayName = 'OnboardingEmployerDetails'
}

export default OnboardingEmployerDetails
