import AccountsView from 'features/Accounts/AccountsView'
import AnnouncementsView from 'features/Concierge/Announcements/AnnouncementsView'
import ConciergeView from 'features/Concierge/ConciergeView'
import FaqView from 'features/Concierge/Faq/FaqView'
import DashboardView from 'features/Dashboard/DashboardView'
import ErrorView from 'features/ErrorView'
import FlexActivationView from 'features/Flex/Activation/FlexActivationView'
import FlexPrograms from 'features/Flex/FlexPrograms'
import FlexManageView from 'features/Flex/Manage/FlexManageView'
import FlexView from 'features/Flex/Overview/FlexView'
import GRHActivationView from 'features/GRH/Activation/GRHActivationView'
import GRHPrograms from 'features/GRH/GRHPrograms'
import GRHManageView from 'features/GRH/Manage/GRHManageView'
import GRHView from 'features/GRH/Overview/GRHView'
import OTPActivationView from 'features/OTP/Activation/OTPActivationView'
import OTPPrograms from 'features/OTP/OTPPrograms'
import OTPManageView from 'features/OTP/Manage/OTPManageView'
import OTPView from 'features/OTP/Overview/OTPView'
import ActivateIncentiveView from 'features/Incentives/Activation/ActivateIncentiveView'
import IncentivePrograms from 'features/Incentives/IncentivePrograms'
import IncentivesManageView from 'features/Incentives/Manage/IncentivesManageView'
import OTRHistoryView from 'features/OTR/OTRHistoryView'
import TripsView from 'features/Incentives/Trips/TripsView'
import LeaderboardActivationView from 'features/Leaderboards/Activation/LeaderboardActivationView'
import LeaderboardsPrograms from 'features/Leaderboards/LeaderboardsPrograms'
import LeaderboardsView from 'features/Leaderboards/LeaderboardsView'
import ManageLeaderboardView from 'features/Leaderboards/Management/ManageLeaderboardView'
import MapIntelligenceView from 'features/MapIntelligence/MapIntelligenceView'
import MicromobilityActivationView from 'features/Micromobility/Activation/MicromobilityActivationView'
import MicromobilityManageView from 'features/Micromobility/Manage/MicromobilityManageView'
import MicromobilityPrograms from 'features/Micromobility/MicromobilityPrograms'
import MicromobilityView from 'features/Micromobility/Overview/MicromobilityView'
import GettingStartedView from 'features/Onboarding/GettingStartedView'
// import OnboardingView from 'features/Onboarding/OnboardingView'
import Playground from 'features/Playground'
import ProviderCatalogView from 'features/ProviderCatalog/ProviderCatalogView'
import QTFActivationView from 'features/QTF/Activation/QTFActivationView'
import QTFManageView from 'features/QTF/Manage/QTFManageView'
import QTFView from 'features/QTF/Overview/QTFView'
import QTFPrograms from 'features/QTF/QTFPrograms'
import ReportView from 'features/Report/ReportView'
import SegmentsView from 'features/Segments/SegmentsView'
import UploadHistoryView from 'features/Segments/UploadHistoryView'
import EmployeeSignUpView from 'features/Users/EmployeeSignUpView'
import EmployerSignUpView from 'features/Users/EmployerSignUpView'
import ForgotPasswordView from 'features/Users/ForgotPasswordView'
import LoginView from 'features/Users/LoginView'
import ResetPasswordView from 'features/Users/ResetPasswordView'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DefaultLayout from './DefaultLayout'
import { Locations } from './Locations'
import IntercomWrapper from 'shared/IntercomWrapper'

// Employee Platform Views
import EmployeeOnboardingView from 'employee-platform/features/Onboarding/EmployeeOnboardingView'
import PreTaxOverviewView from 'employee-platform/features/PreTax/PreTaxOverviewView'
import TripsOverviewView from 'employee-platform/features/Trips/TripsOverviewView'
import LeaderboardsOverviewView from 'employee-platform/features/Leaderboards/LeaderboardsOverviewView'
import CarpoolOverviewView from 'employee-platform/features/Carpool/CarpoolOverviewView'
import CarpoolDetailsView from 'employee-platform/features/Carpool/CarpoolDetailsView'
import CarpoolRequestDetailsView from 'employee-platform/features/Carpool/CarpoolRequestDetailsView'
import EmployeeDashboardView from 'employee-platform/features/Dashboard/Overview/EmployeeDashboardView'
import ProfileSettingsView from 'employee-platform/features/Profile/ProfileSettingsView'
import EmployeeConciergeView from 'employee-platform/features/Concierge/EmployeeConciergeView'
import AddTripView from 'employee-platform/features/Trips/AddTripView'
import EmployeeGRHOverviewView from 'employee-platform/features/GRH/EmployeeGRHOverviewView'
import OptInPrograms from 'features/OptIn/OptInPrograms'
import OptInOverviewView from 'features/OptIn/Overview/OptInOverviewView'
import OptInQuestionOverviewView from 'features/OptIn/QuestionOverview/OptInQuestionOverviewView'
import EmployeeOptInOverviewView from 'employee-platform/features/OptIn/EmployeeOptInOverviewView'
import EmployerEmailConfirmationView from 'features/Users/EmployerEmailConfirmationView'
import DefaultLayoutV2 from './DefaultLayoutV2'
import { useStatsigUser } from '@statsig/react-bindings'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'

const RootRoutes = () => {
  const { updateUserAsync } = useStatsigUser()
  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  useEffect(() => {
    const updateUser = async () => {
      if (profileData && profileData.id !== undefined) {
        try {
          await updateUserAsync({
            userID: profileData.id,
            email: profileData.email,
            customIDs: {
              employerID: profileData.employerId,
              worksiteID: profileData.worksiteId,
            },
            custom: {
              companyName: profileData.name,
              segmentNameList: profileData.segments.map(
                (segment) => segment.name
              ),
            },
          })
        } catch (error) {
          console.error('Failed to update user:', error)
        }
      }
    }
    updateUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData])

  return (
    <BrowserRouter>
      <Routes>
        <Route path={Locations.Login} element={<LoginView />} />
        <Route
          path={Locations.ForgotPassword}
          element={<ForgotPasswordView />}
        />
        <Route path={Locations.ResetPassword} element={<ResetPasswordView />} />
        <Route
          path={Locations.EmployeeSignUp}
          element={<EmployeeSignUpView />}
        />
        <Route
          path={Locations.EmployerSignUp}
          element={<EmployerSignUpView />}
        />
        <Route
          path={Locations.EmployerEmailConfirmation}
          element={<EmployerEmailConfirmationView />}
        />
        <Route path={Locations.Report} element={<ReportView />} />
        {/* <Route path={Locations.Onboarding} element={<OnboardingView />} /> */}
        <Route
          path={'/'}
          element={
            <DefaultLayoutV2 showHeaderNav={false} showSidebar={false} />
          }
        >
          <Route
            path={Locations.GettingStarted}
            element={
              <IntercomWrapper>
                <GettingStartedView />
              </IntercomWrapper>
            }
          />
        </Route>
        {/* Main App */}
        <Route path={'/'} element={<DefaultLayout />}>
          <Route path={Locations.Dashboard} element={<DashboardView />} />
          <Route path={Locations.Accounts.Profile} element={<AccountsView />} />
          <Route path={Locations.Accounts.Billing} element={<AccountsView />} />
          <Route
            path={Locations.Accounts.Products}
            element={<AccountsView />}
          />

          <Route
            path={Locations.MapIntelligence}
            element={<MapIntelligenceView />}
          />
          <Route
            path={Locations.Segments.UploadHistory}
            element={<UploadHistoryView />}
          />
          <Route path={Locations.Concierge.Faq} element={<FaqView />} />
          <Route
            path={Locations.Concierge.Announcement}
            element={<AnnouncementsView />}
          />

          {/* Incentives */}
          <Route
            path={Locations.Incentives.Programs}
            element={<IncentivePrograms />}
          />
          <Route
            path={Locations.Incentives.Activation}
            element={<ActivateIncentiveView />}
          />
          <Route
            path={Locations.Incentives.Manage}
            element={<IncentivesManageView />}
          />
          <Route path={Locations.Incentives.Trips} element={<TripsView />} />

          {/* OTR */}
          <Route
            path={Locations.Incentives.OTRHistory}
            element={<OTRHistoryView />}
          />
          <Route path={Locations.OTR.OTRHistory} element={<OTRHistoryView />} />

          {/* Leaderboards */}
          <Route
            path={Locations.Leaderboards.Programs}
            element={<LeaderboardsPrograms />}
          />
          <Route
            path={Locations.Leaderboards.Programs}
            element={<LeaderboardsPrograms />}
          />
          <Route
            path={Locations.Leaderboards.Leaderboards}
            element={<LeaderboardsView />}
          />
          <Route
            path={Locations.Leaderboards.Manage}
            element={<ManageLeaderboardView />}
          />
          <Route
            path={Locations.Leaderboards.Activation}
            element={<LeaderboardActivationView />}
          />

          {/* Concierge */}
          <Route
            path={Locations.Concierge.Concierge}
            element={<ConciergeView />}
          />

          {/* Flex */}
          <Route path={Locations.Flex.Programs} element={<FlexPrograms />} />
          <Route path={Locations.Flex.Overview} element={<FlexView />} />
          <Route
            path={Locations.Flex.Activation}
            element={<FlexActivationView />}
          />
          <Route path={Locations.Flex.Manage} element={<FlexManageView />} />

          {/* Micromobility */}
          <Route
            path={Locations.Micromobility.Programs}
            element={<MicromobilityPrograms />}
          />
          <Route
            path={Locations.Micromobility.Overview}
            element={<MicromobilityView />}
          />
          <Route
            path={Locations.Micromobility.Activation}
            element={<MicromobilityActivationView />}
          />
          <Route
            path={Locations.Micromobility.Manage}
            element={<MicromobilityManageView />}
          />

          {/* QTF */}
          <Route path={Locations.QTF.Programs} element={<QTFPrograms />} />
          <Route path={Locations.QTF.Overview} element={<QTFView />} />
          <Route
            path={Locations.QTF.Activation}
            element={<QTFActivationView />}
          />
          <Route
            path={Locations.QTF.ActivationOnboarding}
            element={
              <IntercomWrapper>
                <QTFActivationView isOnboarding={true} />
              </IntercomWrapper>
            }
          />
          <Route
            path={Locations.QTF.ActivationDefault}
            element={<QTFActivationView isOnboarding={false} />}
          />
          <Route path={Locations.QTF.Manage} element={<QTFManageView />} />

          {/* GRH */}
          <Route path={Locations.GRH.Programs} element={<GRHPrograms />} />
          <Route path={Locations.GRH.Overview} element={<GRHView />} />
          <Route
            path={Locations.GRH.Activation}
            element={<GRHActivationView />}
          />
          <Route path={Locations.GRH.Manage} element={<GRHManageView />} />

          {/* OPT IN */}
          <Route path={Locations.OptIn.Programs} element={<OptInPrograms />} />
          <Route
            path={Locations.OptIn.Overview}
            element={<OptInOverviewView />}
          />
          <Route
            path={Locations.OptIn.QuestionOverview}
            element={<OptInQuestionOverviewView />}
          />

          {/* OTP */}
          <Route path={Locations.OTP.Programs} element={<OTPPrograms />} />
          <Route path={Locations.OTP.Overview} element={<OTPView />} />
          <Route
            path={Locations.OTP.Activation}
            element={<OTPActivationView />}
          />
          <Route path={Locations.OTP.Manage} element={<OTPManageView />} />
          <Route
            path={Locations.ProviderCatalog}
            element={<ProviderCatalogView />}
          />
          <Route
            path={Locations.Segments.Overview}
            element={<SegmentsView />}
          />

          {/* Employee Platform Routes */}
          <Route
            path={Locations.EmployeeOnboarding}
            element={<EmployeeOnboardingView />}
          />
          <Route
            path={Locations.EmployeeDashboard}
            element={<EmployeeDashboardView />}
          />
          <Route
            path={Locations.EmployeeSettings}
            element={<ProfileSettingsView />}
          />
          <Route
            path={Locations.EmployeePreTax.Overview}
            element={<PreTaxOverviewView />}
          />
          <Route
            path={Locations.EmployeeCarpool.Overview}
            element={<CarpoolOverviewView />}
          />
          <Route
            path={Locations.EmployeeCarpool.CarpoolDetails}
            element={<CarpoolDetailsView />}
          />
          <Route
            path={Locations.EmployeeCarpool.CarpoolRequestDetails}
            element={<CarpoolRequestDetailsView />}
          />
          <Route
            path={Locations.EmployeeGRH.Overview}
            element={<EmployeeGRHOverviewView />}
          />
          <Route
            path={Locations.EmployeeTrips.Overview}
            element={<TripsOverviewView />}
          />
          <Route
            path={Locations.EmployeeTrips.AddTrip}
            element={<AddTripView />}
          />
          <Route
            path={Locations.EmployeeLeaderboards.Overview}
            element={<LeaderboardsOverviewView />}
          />
          <Route
            path={Locations.EmployeeOptIn.Overview}
            element={<EmployeeOptInOverviewView />}
          />
          <Route
            path={Locations.EmployeeConcierge}
            element={<EmployeeConciergeView />}
          />
        </Route>

        {process.env.NODE_ENV === 'development' && (
          <Route path='/playground' element={<Playground />} />
        )}
        <Route path='*' element={<ErrorView />} />
      </Routes>
    </BrowserRouter>
  )
}

RootRoutes.displayName = 'RootRoutes'

export default RootRoutes
