import Button from 'core-system/Button'
import ColumnView, { Column } from 'core-system/ColumnView'
import FlexContainer from 'core-system/FlexContainer'
import Loading from 'core-system/Loading'
import * as Program from 'core-system/Program'
import moment from 'moment'
import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import SegmentService from 'redux/config/services/SegmentService'
import { AppState } from 'redux/config/store'
import { qtfActions } from 'redux/qtf/qtfSlice'
import DateUtils from 'shared/DateUtils'
import useQueryParam from 'shared/Hooks/useQueryParam'
import QTFActivationSetup from './components/QTFActivationSetup'
import QTFActivationSummary from './components/QTFActivationSummary'
import QTFProgramComms from './components/QTFProgramComms'
import QTFUtils from '../Shared/QTFUtils'
import QTFOnboardingSkipModal from './components/QTFOnboardingSkipModal'
import { Locations } from 'shared/Router/Locations'
import ButtonV2 from 'core-system/ButtonV2'
import pxToRem from 'core-system/utils/pxToRem'
import AlertBanner from 'core-system/AlertBanner'

const getContribStartDate = (startDate: string) => {
  return moment(startDate, 'MMMM Do YYYY').subtract(1, 'M')
}

const next2Month = (isOnOrAfter27th?: boolean) => {
  return {
    val: 'next2-month',
    text: DateUtils.getNextXMonths(2, isOnOrAfter27th ? 2 : 1)[0].text,
  }
}

const isOnOrAfter27th = Number(moment().format('D')) >= 27

interface QTFActivationViewProps {
  isOnboarding?: boolean
}

const QTFActivationView = React.memo((props: QTFActivationViewProps) => {
  const { isOnboarding } = props
  const segmentId = useQueryParam('segmentId')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { segmentsMap, worksites } = useSelector(
    (state: AppState) => state.employer
  )
  const currentUser = useSelector((state: AppState) => state.user.profile)
  const qtfPrograms = useSelector((state: AppState) => state.qtf.qtfPrograms)

  const [startDate, setStartDate] = useState(next2Month(isOnOrAfter27th))

  const [employerTransitContribution, setEmployerTransitContribution] =
    useState(0)
  const [employerParkingContribution, setEmployerParkingContribution] =
    useState(0)
  const [transitActive, setTransitActive] = useState(true)
  const [parkingActive, setParkingActive] = useState(true)
  const [commUserIds, setCommUserIds] = useState([currentUser.id])
  const [estimatedMonthlySpend, setEstimatedMonthlySpend] = useState<number>(0)
  const [selectedWorksiteSegments, setSelectedWorksiteSegments] = useState<
    string[]
  >([])
  const [showAlertBanner, setShowAlertBanner] = useState(false)
  const [numSelectedContractors, setNumSelectedContractors] = useState(0)

  const [hrisConfig, setHrisConfig] = useState({
    closingDate: new Date(moment(startDate.text, 'MMMM Do YYYY').format()),
    payrollSchedule: 'BI_WEEKLY',
  })

  // hris connect
  const hrisStatus = useSelector((state: AppState) => ({
    providerName: state.employer.profile?.payrollProviderId,
    reauth: state.employer.profile?.finchReauth,
  }))

  const [isQTFOnboardingSkipModalOpen, setIsQTFOnboardingSkipModalOpen] =
    useState<boolean>(worksites.length === 0)

  const contribStartDate = useMemo(() => {
    return getContribStartDate(startDate.text).format('MMMM Do, YYYY')
  }, [startDate])

  const handleContributionToggle = (type: 'parking' | 'transit') => {
    if (type === 'parking') {
      setParkingActive(!parkingActive)
    } else if (type === 'transit') {
      setTransitActive(!transitActive)
    }
  }

  const handleActivate = () => {
    const startDateFormatted = moment(startDate.text, 'MMMM Do YYYY').format()
    const taxContributionTypes = []
    const formattedNextValidClose = moment(hrisConfig.closingDate).format(
      'YYYY-MM-DD'
    )

    if (transitActive) taxContributionTypes.push('COMMUTER_TRANSIT')
    if (parkingActive) taxContributionTypes.push('COMMUTER_PARKING')

    if (segmentId) {
      dispatch(
        qtfActions.createQtfProgram({
          startDate: startDateFormatted,
          endDate: null,
          segment: segmentId,
          contributionDeadlineDay: contributionDays,
          types: taxContributionTypes,
          manual: setupMode === 'manual',
          nextValidClose: setupMode === 'hris' ? formattedNextValidClose : null,
          payrollCycle:
            setupMode === 'hris' ? hrisConfig.payrollSchedule : null,
          commUserIds,
          employerTransitContribution: employerTransitContribution,
          employerParkingContribution: employerParkingContribution,
        })
      )

      SegmentService.track('programs-action-click', {
        action: 'activate',
        programType: 'qtf',
        segmentName: segmentsMap[segmentId].name,
        startDate: moment(startDateFormatted).format('DD-MM-YYYY'),
        types: taxContributionTypes,
        contributionDeadlineDay: contributionDays,
        manual: setupMode === 'manual',
        nextValidClose: setupMode === 'hris' ? formattedNextValidClose : null,
        payrollCycle: setupMode === 'hris' ? hrisConfig.payrollSchedule : null,
      })
    } else {
      selectedWorksiteSegments.forEach((segmentId) => {
        dispatch(
          qtfActions.createQtfProgram({
            startDate: startDateFormatted,
            endDate: null,
            segment: segmentId,
            contributionDeadlineDay: contributionDays,
            types: taxContributionTypes,
            manual: setupMode === 'manual',
            nextValidClose:
              setupMode === 'hris' ? formattedNextValidClose : null,
            payrollCycle:
              setupMode === 'hris' ? hrisConfig.payrollSchedule : null,
            commUserIds,
            employerTransitContribution: employerTransitContribution,
            employerParkingContribution: employerParkingContribution,
          })
        )

        SegmentService.track('programs-action-click', {
          action: 'activate',
          programType: 'qtf',
          segmentName: segmentsMap[segmentId].name,
          startDate: moment(startDateFormatted).format('DD-MM-YYYY'),
          types: taxContributionTypes,
          contributionDeadlineDay: contributionDays,
          manual: setupMode === 'manual',
          nextValidClose: setupMode === 'hris' ? formattedNextValidClose : null,
          payrollCycle:
            setupMode === 'hris' ? hrisConfig.payrollSchedule : null,
        })

        navigate(Locations.QTF.Programs)
      })
    }
  }

  const handleSetupModeChange = (mode: 'manual' | 'hris') => {
    setSetupMode(mode)
    if (mode === 'hris') {
      setContributionDays(20)
    } else {
      setContributionDays(25)
    }
  }

  const handleDateChange = (val: string, text: string, initial: boolean) => {
    const updatedDate = initial ? next2Month() : { val, text }
    setStartDate(updatedDate)
    setHrisConfig((prev) => {
      return {
        ...prev,
        closingDate: new Date(
          moment(updatedDate.text, 'MMMM Do YYYY').format()
        ),
      }
    })
  }

  const handleSegmentChange = (newSegmentId: string) => {
    if (newSegmentId !== segmentId) {
      navigate(`/tax-savings/${newSegmentId}/activation`)
      SegmentService.track('baseOptions-toggle-action', {
        actionType: 'segment-name',
      })
    }
  }

  const handleContributionDayChange = (newDay: number) => {
    if (newDay <= 27 && newDay >= 5) {
      setContributionDays(newDay)
    }
  }

  const handleEmployerTransitContributionChange = (amount: number) => {
    setEmployerTransitContribution((amount || 0) * 100)
  }

  const handleEmployerParkingContributionChange = (amount: number) => {
    setEmployerParkingContribution((amount || 0) * 100)
  }

  const handleHrisConfig = (updatedConfig: any) => {
    setHrisConfig(updatedConfig)
  }

  const getActivateButton = () => {
    return (
      <FlexContainer marginLeft='auto'>
        {!isOnboarding && segmentId && (
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => navigate(`/tax-savings/${segmentId}`)}
          >
            Cancel
          </Button>
        )}
        {!isOnboarding && !segmentId && (
          <Button
            variant='tertiary'
            marginRight='1rem'
            onClick={() => navigate(`/tax-savings/`)}
          >
            Cancel
          </Button>
        )}
        <ButtonV2
          style={{ width: '100%', height: pxToRem(54) }}
          disabled={
            (!transitActive && !parkingActive) ||
            (setupMode === 'hris' && !hrisStatus.providerName) ||
            (setupMode === 'hris' && hrisStatus.reauth) ||
            QTFUtils.monthlyPreTaxParking < employerParkingContribution ||
            employerParkingContribution < 0 ||
            QTFUtils.monthlyPreTaxTransit < employerTransitContribution ||
            employerTransitContribution < 0 ||
            (moment(contribStartDate, 'MMMM Do, YYYY').isBefore(moment()) &&
              contributionDays <= Number(moment().format('D')))
          }
          onClick={handleActivate}
        >
          Launch Program
        </ButtonV2>
      </FlexContainer>
    )
  }

  const areAutoBenefitsAllowed = useMemo(() => {
    return (
      worksites &&
      segmentId &&
      worksites.reduce((allowed, worksite) => {
        if (
          worksite.segmentId === segmentId &&
          worksite.canUseAutomatedBenefits
        ) {
          allowed = true
        }
        return allowed
      }, false)
    )
  }, [worksites, segmentId])

  const [setupMode, setSetupMode] = useState<'manual' | 'hris'>(
    areAutoBenefitsAllowed ? 'hris' : 'manual'
  )
  const [contributionDays, setContributionDays] = useState(
    setupMode === 'manual' ? 27 : 20
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const numContractors = selectedWorksiteSegments.reduce(
      (contractors, segmentId) => {
        return contractors + segmentsMap?.[segmentId]?.contractors.length
      },
      0
    )
    setNumSelectedContractors(numContractors)
    if (numContractors > 0) {
      setShowAlertBanner(true)
    }
  }, [selectedWorksiteSegments, segmentsMap])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (segmentId) {
      const numContractors = segmentsMap?.[segmentId]?.contractors.length
      setNumSelectedContractors(numContractors)
      if (numContractors > 0) {
        setShowAlertBanner(true)
      }
    }
  }, [segmentId, segmentsMap])

  if (!qtfPrograms || !segmentsMap) {
    return <Loading fullPage />
  }

  if (qtfPrograms[segmentId]) {
    return <Navigate to={`/tax-savings/${segmentId}`} replace />
  }

  return (
    <>
      {showAlertBanner && numSelectedContractors > 0 && (
        <AlertBanner
          variant='yellowAlert'
          message={`${numSelectedContractors} commuter${
            numSelectedContractors === 1 ? '' : 's'
          } at the worksite${
            selectedWorksiteSegments.length === 1 || segmentId ? '' : 's'
          } you have selected are contractors. They are not eligible for pre-tax benefits`}
          closeFn={() => setShowAlertBanner(false)}
          marginBottom='1rem'
        />
      )}
      <ColumnView defaultView>
        <Column>
          {segmentId && (
            <Program.SegmentAndDateConfig
              currentSegment={segmentsMap[segmentId]}
              onDateChange={handleDateChange}
              onSegmentChange={handleSegmentChange}
              startDateDescription={
                'Date when commuters are able to begin spending funds.'
              }
              isLateStart={isOnOrAfter27th}
              showDates2MonthsLater={isOnOrAfter27th}
              excludeContractors={true}
            />
          )}
          <QTFActivationSetup
            contribStartDate={getContribStartDate(startDate.text)}
            setupMode={setupMode}
            setSetupMode={handleSetupModeChange}
            canEdit={true}
            transitActive={transitActive}
            parkingActive={parkingActive}
            employerTransitContribution={employerTransitContribution}
            employerParkingContribution={employerParkingContribution}
            onEmployerTransitContributionChange={
              handleEmployerTransitContributionChange
            }
            onEmployerParkingContributionChange={
              handleEmployerParkingContributionChange
            }
            handleToggle={handleContributionToggle}
            contributionDays={contributionDays}
            onContributionDayChange={handleContributionDayChange}
            hrisConfig={hrisConfig}
            setHrisConfig={handleHrisConfig}
            activationView={true}
            initialSetupDate={
              new Date(moment(startDate.text, 'MMMM Do YYYY').format())
            }
            hrisConnectReAuth={hrisStatus.reauth}
            hrisProviderName={hrisStatus.providerName}
            canUseAutomatedBenefits={areAutoBenefitsAllowed}
            isOnboarding={isOnboarding}
            handleDateChange={handleDateChange}
            setEstimatedMonthlySpend={setEstimatedMonthlySpend}
            setSelectedWorksiteSegments={setSelectedWorksiteSegments}
            allWorksites={worksites}
            segmentId={segmentId}
            activateButton={getActivateButton()}
          />
          {!isOnboarding && (
            <QTFProgramComms
              selectedUserIds={commUserIds}
              canEdit={true}
              onUserIdsChange={(ids) => setCommUserIds(ids)}
            />
          )}
        </Column>
        <Column>
          <QTFActivationSummary
            startDate={getContribStartDate(startDate.text).format(
              'MMMM Do, YYYY'
            )}
            currentSegment={segmentsMap[segmentId]}
            transitActive={transitActive}
            parkingActive={parkingActive}
            employerTransitContribution={employerTransitContribution}
            employerParkingContribution={employerParkingContribution}
            contributionDays={contributionDays}
            isActivationFlow
            commUserIds={commUserIds}
            setupMode={setupMode}
            hrisConfig={hrisConfig}
            activationView={true}
            estimatedMonthlySpend={estimatedMonthlySpend}
            isOnboarding={isOnboarding}
          />
        </Column>
      </ColumnView>

      <QTFOnboardingSkipModal
        open={isQTFOnboardingSkipModalOpen}
        closeModal={() => setIsQTFOnboardingSkipModalOpen(false)}
      />
    </>
  )
})

// Helps to identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  QTFActivationView.displayName = 'QTFActivationView'
}

export default QTFActivationView
